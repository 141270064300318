import Link from 'next/link';

const NotFoundPage = () => {
	return (
		<section className='dark:bg-gray-900 dark:text-gray-100 flex h-[100vh] w-full items-center p-16'>
			<div className='container mx-auto my-8 flex flex-col items-center justify-center px-5'>
				<div className='max-w-auto text-center'>
					<h2 className={'dark:text-gray-600 mb-8 text-9xl font-extrabold'}>
						<span className='sr-only'>Error</span>
						404
					</h2>
					<p className='text-2xl md:text-3xl mb-8 font-semibold'>
						Xin lỗi, chúng tôi không thể tìm thấy trang này.
					</p>
					<Link rel='noopener noreferrer' href='/' className='rounded px-8 py-3 font-semibold'>
						<button className='btn btn-primary'>Quay lại trang chủ</button>
					</Link>
				</div>
			</div>
		</section>
	);
};

export default NotFoundPage;
